import React, { FC } from "react";
import "./EventInfo.scss";
import { IEventData } from "../../types/eventTypes";
import ViewInput from "../../components/reusable/viewInput/ViewInput";
import dayjs from "dayjs";
import { EVENT_COLORS } from "../../constants/common";
import EventColorOption from "../../forms/EventForm/components/EventColorOption/EventColorOption";
import { useQuery } from "@apollo/client";
import { GET_VENUE } from "graphql/query/getVenue";

interface IEventInfoProps {
  event: IEventData;
}

const EventInfo: FC<IEventInfoProps> = ({ event }) => {
  const { data: venueData } = useQuery(GET_VENUE, {
    variables: { id: event.venueId },
  });

  return (
    <div className={"event-info"}>
      <ViewInput
        title={"Duration"}
        value={`${dayjs(event?.startDate).format("DD-MM-YYYY HH:mm")} - ${dayjs(
          event?.endDate
        ).format("DD-MM-YYYY HH:mm")}`}
      />

      <ViewInput title={"Venue"} value={venueData.name} />
      <ViewInput
        title={"Event description"}
        value={event.description}
        newClass={"event-info--data-desc"}
      />
      {event?.color && (
        <div className="event-info--color-container">
          <p>Event color</p>
          <EventColorOption
            option={{
              label: EVENT_COLORS.find((color) => color.value === event.color)?.label || "",
              value: event.color as string,
            }}
          />
        </div>
      )}
      {event?.saleStartDate && event?.saleStartDate ? (
        <>
          <div className="ticket-sales-title">Ticket sales date</div>

          <ViewInput
            title={"From"}
            value={`${dayjs(event?.saleStartDate).format("DD-MM-YYYY HH:mm")}`}
          />
          <ViewInput
            title={"To"}
            value={`${dayjs(event?.saleStartDate).format("DD-MM-YYYY HH:mm")}`}
          />
        </>
      ) : null}
    </div>
  );
};

export default EventInfo;
