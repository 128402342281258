import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import BaseError from "../../components/reusable/baseError/BaseError";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import EventView from "../../pageView/EventView/EventView";
import { observer } from "mobx-react-lite";
import { GET_EVENT_REQUEST } from "../../graphql/query/getEventRequest";

const EventRequest = observer(() => {
  const { eventRequestId } = useParams();

  const [loading, setLoading] = useState(true);

  const [getEventRequest, { data, error }] = useLazyQuery(GET_EVENT_REQUEST, {
    fetchPolicy: "no-cache",
  });

  const fetchEventRequest = useCallback(async () => {
    try {
      if (eventRequestId) {
        await getEventRequest({
          variables: {
            id: +eventRequestId,
          },
        });
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [eventRequestId, getEventRequest]);

  useEffect(() => {
    fetchEventRequest();
  }, [fetchEventRequest]);

  if (error) {
    return <BaseError>{error?.message}</BaseError>;
  }

  if (loading) {
    return <BaseLoader />;
  }

  return <EventView eventData={data?.findEventRequest} showEditEvent={false} />;
});

export default EventRequest;
