import React, { FC, useCallback, useEffect, useState } from "react";
import {
  UseFormRegister,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormGetValues,
} from "react-hook-form";
import BaseInput from "../../../../components/reusable/baseInput/BaseInput";
import { IEventData } from "../../../../types/eventTypes";
import "./EventDetails.scss";
import BaseSelect from "../../../../components/reusable/baseSelect/BaseSelect";
import { IVenue } from "../../../../types/venue";
import RangePicker from "../../../../components/reusable/rangePicker/RangePicker";
import DatePicker from "../../../../components/reusable/datePicker/DatePicker";
// import TimePicker from "../../../../components/reusable/timePicker/TimePicker";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { ISelect, Maybe } from "types/types";
import { EVENT_COLORS } from "../../../../constants/common";
import EventColorOption from "../EventColorOption/EventColorOption";
import { useQuery } from "@apollo/client";
import { GET_VENUES_LIST } from "graphql/query/getVenues";
import { useDebounce } from "hooks/useDebounce";

interface RenderOptionProps {
  option:
    | {
        label: string;
        value: string;
      }
    | ISelect;
  onClick: () => void;
  isActive: boolean;
}

interface EventDetailsProps {
  selectVenueId: number | string;
  clearErrors: UseFormClearErrors<IEventData>;
  isDisabled: boolean;
  register: UseFormRegister<IEventData>;
  setValue: UseFormSetValue<IEventData>;
  getValues: UseFormGetValues<IEventData>;
  errors: FieldErrors<IEventData>;
}

export interface VenueOption {
  id: number;
  value: string;
  type?: string;
}

const EventDetails: FC<EventDetailsProps> = ({
  selectVenueId,
  clearErrors,
  isDisabled,
  register,
  setValue,
  getValues,
  errors,
}) => {
  const [searchVenueName, setSearchVenueName] = useState("");
  const debSearchVenueName = useDebounce(searchVenueName);
  const { data, fetchMore } = useQuery(GET_VENUES_LIST, {
    variables: { listVenuesArgs: { offset: 0, limit: 100, name: debSearchVenueName } },
  });

  const venueList = data?.listVenues?.venues as Array<IVenue>;

  const changeVenueId = (value: VenueOption) => {
    setValue("venueId", value.id);
    clearErrors("venueId");
  };

  const onChangeRange = useCallback(
    (values: [Maybe<Dayjs>, Maybe<Dayjs>] | null) => {
      const [startDate, endDate] = values || [];

      if (startDate || endDate) {
        startDate && setValue("startDate", startDate);
        endDate && setValue("endDate", endDate);
        clearErrors("startDate");
        clearErrors("endDate");
      }
    },
    [clearErrors, setValue]
  );

  const onChangeEventColor = useCallback((value: string) => setValue("color", value), [setValue]);

  const onSaleStartDateChange = useCallback(
    (value: Dayjs | null) => {
      value && setValue("saleStartDate", value);
      clearErrors("saleStartDate");
    },
    [clearErrors, setValue]
  );
  const onSaleEndDateChange = useCallback(
    (value: Dayjs | null) => {
      value && setValue("saleExpireDate", value);
      clearErrors("saleExpireDate");
    },
    [clearErrors, setValue]
  );

  useEffect(() => {
    if (!Object.keys(getValues()).length) {
      setValue("venueId", venueList?.find((venue) => venue.id === +selectVenueId)?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOption = useCallback(
    ({ option, onClick, isActive }: RenderOptionProps) => (
      <EventColorOption
        isActive={isActive}
        onClick={onClick}
        option={
          option as {
            label: string;
            value: string;
          }
        }
      />
    ),
    []
  );

  const onFetchNextVenues = () => {
    fetchMore({
      variables: {
        listVenuesArgs: { offset: venueList?.length || 0, limit: 100, name: debSearchVenueName },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          ...previousResult,
          listVenues: {
            ...previousResult.listVenues,
            venues: [...previousResult.listVenues?.venues, ...fetchMoreResult.listVenues?.venues],
          },
        };
      },
    });
  };

  return (
    <div>
      <div className={"input-field-distance"}>
        <BaseInput
          disabled={isDisabled}
          label={{
            text: "Event Name",
            htmlFor: "name",
            className: "required",
          }}
          newClassName={"modal-input"}
          registerValidation={{
            type: "text",
            name: "name",
            placeholder: "Name...",
            validation: {
              ...register("name"),
            },
          }}
          error={errors.name?.message}
        />
      </div>
      <div className={"input-field-distance"}>
        <BaseInput
          disabled={isDisabled}
          newClassName={"modal-input"}
          label={{
            text: "Event Description",
            htmlFor: "description",
            className: "required",
          }}
          registerValidation={{
            type: "textarea",
            name: "description",
            placeholder: "description...",
            validation: {
              ...register("description"),
            },
          }}
        />
      </div>

      <div className={"event-date-range"}>
        <div className={"input-field-distance"}>
          <RangePicker
            title="Duration"
            newClassName="modal-input"
            // error={errors.startDate?.message}
            onChange={onChangeRange}
            value={[dayjs(getValues("startDate")), dayjs(getValues("endDate"))]}
            format={"DD-MM-YYYY HH:mm"}
            showTime={{
              format: "HH:mm",
              minuteStep: 30,
            }}
          />
        </div>
        <div className={"input-field-distance"}>
          <BaseSelect
            hasSearch={true}
            value={venueList?.find((venue) => venue.id === +selectVenueId)?.name}
            setValue={(value) => changeVenueId(value as VenueOption)}
            disabled={isDisabled || false}
            options={venueList || []}
            showOnlyName={true}
            label={{
              text: "Venues",
              htmlFor: "Venues",
              className: "required",
            }}
            registerValidation={{
              type: "select",
              name: "venueId",
              placeholder: "Venues",
              validation: {},
            }}
            isApiLevelSearch={true}
            onSearchTextChange={setSearchVenueName}
            withPagination={true}
            onFetchNextPage={onFetchNextVenues}
            error={errors.venueId?.message}
          />
        </div>
      </div>
      <span className="ticket-sales-title">Ticket sales date</span>
      <div className="event-date-range">
        <div className={"input-field-distance"}>
          <DatePicker
            title="From"
            newClassName="modal-input"
            error={errors.saleStartDate?.message}
            showTime={{
              format: "HH:mm",
              minuteStep: 30,
            }}
            onChange={onSaleStartDateChange}
            value={getValues("saleStartDate") || null}
            format={"DD-MM-YYYY HH:mm"}
            disabled={isDisabled}
          />
        </div>
        <div className={"input-field-distance"}>
          <DatePicker
            title="To"
            newClassName="modal-input"
            error={errors.saleExpireDate?.message}
            onChange={onSaleEndDateChange}
            value={getValues("saleExpireDate") || null}
            disabled={isDisabled}
            format={"DD-MM-YYYY HH:mm"}
            showTime={{
              format: "HH:mm",
              minuteStep: 30,
            }}
          />
        </div>
      </div>
      <div className={"input-field-distance"}>
        <BaseSelect
          value={getValues("color")}
          setValue={(option) => {
            onChangeEventColor(option as string);
          }}
          disabled={isDisabled || false}
          options={EVENT_COLORS}
          renderOption={renderOption}
          showOnlyName={true}
          label={{
            text: "Event Card color",
            htmlFor: "Event Card color",
            className: "required",
          }}
          registerValidation={{
            type: "select",
            name: "color",
            placeholder: "Event Card color",
            validation: {},
          }}
          error={errors.color?.message}
          optionsClassName="event-color-options"
          renderCustomCustomSelectOption={({ selectedItem }) => (
            <EventColorOption
              option={{
                label: EVENT_COLORS.find((color) => color.value === selectedItem)?.label || "",
                value: selectedItem as string,
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default EventDetails;
