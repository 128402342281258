import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import "./BaseSelect.scss";
import { ILabel, ISelect, IValidationInput, Maybe } from "../../../types/types";
import useOnClickOutside from "../../../hooks/useOutsideClickEvent";
import { ReactComponent as ArrowDown } from "../../../assets/images/arrowDown.svg";
import BaseInput from "../baseInput/BaseInput";

interface CustomOption {
  label: string;
  value: string;
}

interface BaseSelectProps {
  value?: number | string;
  title?: string;
  disabled?: boolean;
  options:
    | ISelect[]
    | {
        label: string;
        value: string;
      }[];
  showOnlyType?: boolean;
  showOnlyName?: boolean;
  label: ILabel;
  registerValidation: IValidationInput;
  setValue: (value: { id: number; value: string; type?: string } | string) => void;
  error?: any;
  hasSearch?: boolean;
  isApiLevelSearch?: boolean;
  onSearchTextChange?: (searchText: string) => void;
  className?: string;
  renderOption?: ({
    option,
    isActive,
    onClick,
  }: {
    option: ISelect | CustomOption;
    isActive: boolean;
    onClick: () => void;
  }) => JSX.Element;
  optionsClassName?: string;
  renderCustomCustomSelectOption?: ({
    selectedItem,
  }: {
    selectedItem: string | number;
  }) => JSX.Element;
  withPagination?: boolean;
  onFetchNextPage?: () => void;
}

const BaseSelect: FC<BaseSelectProps> = (props) => {
  const {
    value,
    title = "Select",
    disabled,
    options,
    label,
    registerValidation,
    setValue,
    error,
    showOnlyType = false,
    showOnlyName = false,
    hasSearch = false,
    isApiLevelSearch = false,
    onSearchTextChange,
    className,
    renderOption,
    optionsClassName,
    renderCustomCustomSelectOption,
    withPagination = false,
    onFetchNextPage,
  } = props;

  const observerTarget = useRef(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const [selectItem, setSelectItem] = useState<string | number>("");
  const [searchValue, setSearchValue] = useState("");

  const [isOpen, setOpen] = useState(false);
  const clickOutsideHandler = () => {
    setOpen(false);
  };
  useOnClickOutside(selectRef, clickOutsideHandler);

  const handleSelect = (item: any) => {
    if (hasSearch) {
      setSearchValue(item?.name);
      onSearchTextChange && onSearchTextChange(item?.name || "");
    }
    setOpen(false);

    if (item?.name) {
      setSelectItem(showOnlyType ? item?.type : item?.name);
      setValue({ id: item?.id, value: item?.name, type: item?.type });
      return;
    }

    setSelectItem(item);
    setValue(item);
  };

  const optionsToRender = useMemo(() => {
    if (searchValue && !isApiLevelSearch) {
      try {
        return [...options].sort(
          (a, b) =>
            ((b as ISelect).type?.toLowerCase().match(new RegExp(searchValue, "gi")) || []).length -
            ((a as ISelect).type?.toLowerCase().match(new RegExp(searchValue, "gi")) || []).length
        );
      } catch (err) {
        console.log(err);
      }
    }
    return options;
  }, [isApiLevelSearch, options, searchValue]);

  useEffect(() => {
    if (typeof value === "string" || typeof value === "number") {
      setSelectItem(value);
    }
  }, [value]);

  useEffect(() => {
    let observer: Maybe<IntersectionObserver> = null;
    if (typeof window !== "undefined") {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && withPagination) {
            onFetchNextPage && onFetchNextPage();
          }
        },
        { threshold: 0.5 }
      );

      if (observerTarget.current) {
        observer.observe(observerTarget.current);
      }
    }

    return () => {
      if (observerTarget.current && observer) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [onFetchNextPage, withPagination]);

  const renderSelectOption = useMemo(() => {
    if (!!renderCustomCustomSelectOption && selectItem) {
      return renderCustomCustomSelectOption({ selectedItem: selectItem });
    }

    if (hasSearch) {
      return (
        <input
          className={"input"}
          name={registerValidation.name}
          placeholder={registerValidation.placeholder}
          {...{
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              setSelectItem("");
              onSearchTextChange && onSearchTextChange(e.target.value);
              setSearchValue(e.target.value);
            },
            value: searchValue,
          }}
        />
      );
    }

    return <div>{selectItem || title}</div>;
  }, [
    hasSearch,
    renderCustomCustomSelectOption,
    onSearchTextChange,
    searchValue,
    selectItem,
    title,
  ]);

  return (
    <div
      ref={selectRef}
      className={`base-select ${disabled ? "disabled-element" : ""} ${className}`}
    >
      <label htmlFor={`${label.htmlFor}`} className={`base-select--label ${label.className}`}>
        {label.text}
      </label>
      <input {...registerValidation.validation} className={"base-select--input"} />
      <div className={`base-select--wrapper`}>
        <div
          className={`base-select--select ${isOpen ? "base-select--select-open" : ""} ${
            error ? "base-select--select-error" : ""
          }`}
          onClick={() => setOpen((prev) => !prev)}
        >
          <ArrowDown className={"base-select--arrow"} />
          {renderSelectOption}
        </div>
        <div className={`base-select--content ${isOpen ? "base-select--content-open" : ""}`}>
          <div
            className={clsx(
              `base-select--options ${isOpen ? "base-select--options-open" : ""}`,
              optionsClassName
            )}
          >
            {optionsToRender?.map((option, index) =>
              renderOption ? (
                renderOption({
                  option,
                  onClick: () => handleSelect((option as CustomOption).value),
                  isActive: (option as CustomOption).value === selectItem,
                })
              ) : (
                <div
                  className={`base-select--option`}
                  key={index}
                  onClick={() => handleSelect(option)}
                >
                  {showOnlyType && (option as ISelect)?.type}
                  {showOnlyName && (option as ISelect)?.name}
                  {!showOnlyType &&
                    !showOnlyName &&
                    `${(option as ISelect).name} ${(option as ISelect)?.type}`}
                </div>
              )
            )}
            {withPagination && <div ref={observerTarget} />}
          </div>
        </div>
      </div>
      <span className={"base-input--error"}>{error}</span>
    </div>
  );
};

export default BaseSelect;
