export const times = [
  { id: 1, name: "12:00 AM", value: "00:00" },
  { id: 2, name: "12:30 AM", value: "00:30" },
  { id: 3, name: "01:00 AM", value: "01:00" },
  { id: 4, name: "01:30 AM", value: "01:30" },
  { id: 5, name: "02:00 AM", value: "02:00" },
  { id: 6, name: "02:30 AM", value: "02:30" },
  { id: 7, name: "03:00 AM", value: "03:00" },
  { id: 8, name: "03:30 AM", value: "03:30" },
  { id: 9, name: "04:00 AM", value: "04:00" },
  { id: 10, name: "04:30 AM", value: "04:30" },
  { id: 11, name: "05:00 AM", value: "05:00" },
  { id: 12, name: "05:30 AM", value: "05:30" },
  { id: 13, name: "06:00 AM", value: "06:00" },
  { id: 14, name: "06:30 AM", value: "06:30" },
  { id: 15, name: "07:00 AM", value: "07:00" },
  { id: 16, name: "07:30 AM", value: "07:30" },
  { id: 17, name: "08:00 AM", value: "08:00" },
  { id: 18, name: "08:30 AM", value: "08:30" },
  { id: 19, name: "09:00 AM", value: "09:00" },
  { id: 20, name: "09:30 AM", value: "09:30" },
  { id: 21, name: "10:00 AM", value: "10:00" },
  { id: 22, name: "10:30 AM", value: "10:30" },
  { id: 23, name: "11:00 AM", value: "11:00" },
  { id: 24, name: "11:30 AM", value: "11:30" },
  { id: 25, name: "12:00 PM", value: "12:00" },
  { id: 26, name: "12:30 PM", value: "12:30" },

  { id: 3, name: "01:00 PM", value: "13:00" },
  { id: 4, name: "01:30 PM", value: "13:30" },
  { id: 5, name: "02:00 PM", value: "14:00" },
  { id: 6, name: "02:30 PM", value: "14:30" },
  { id: 7, name: "03:00 PM", value: "15:00" },
  { id: 8, name: "03:30 PM", value: "15:30" },
  { id: 9, name: "04:00 PM", value: "16:00" },
  { id: 10, name: "04:30 PM", value: "16:30" },
  { id: 11, name: "05:00 PM", value: "17:00" },
  { id: 12, name: "05:30 PM", value: "17:30" },
  { id: 13, name: "06:00 PM", value: "18:00" },
  { id: 14, name: "06:30 PM", value: "18:30" },
  { id: 15, name: "07:00 PM", value: "19:00" },
  { id: 16, name: "07:30 PM", value: "19:30" },
  { id: 17, name: "08:00 PM", value: "20:00" },
  { id: 18, name: "08:30 PM", value: "20:30" },
  { id: 19, name: "09:00 PM", value: "21:00" },
  { id: 20, name: "09:30 PM", value: "21:30" },
  { id: 21, name: "10:00 PM", value: "22:00" },
  { id: 22, name: "10:30 PM", value: "22:30" },
  { id: 23, name: "11:00 PM", value: "23:00" },
  { id: 24, name: "11:30 PM", value: "23:30" },
  { id: 25, name: "12:00 PM", value: "24:00" },
];
