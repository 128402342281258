import React, { FC, useEffect } from "react";
import "./EventView.scss";
import { IEventData } from "../../types/eventTypes";
import { ReactComponent as Edit } from "../../assets/images/Edit.svg";
import { useNavigate } from "react-router-dom";
import EventInfo from "./EventInfo";
import EventHashtags from "./EventHashtags";
import ImageVideoUploadCarousel from "../../components/ImageVideoUploadCarousel/ImageVideoUploadCarousel";
import filesUploadStore from "../../store/filesUploadStore";
import { filterMediaTypename } from "../../utils/filterMediaTypename";

interface IEventViewProps {
  eventData: { event: IEventData; hashtagIds: string[]; feed: { id: string } };
  venueId?: string | undefined;
  showEditEvent?: boolean;
}

const EventView: FC<IEventViewProps> = ({ eventData, venueId, showEditEvent = true }) => {
  const navigate = useNavigate();

  const editVenue = () => {
    navigate({
      pathname: venueId
        ? `/venues/${venueId}/events/${eventData.event.id.toString()}`
        : `/events/${eventData.event.id.toString()}`,
      search: "step-1",
    });
  };

  useEffect(() => {
    filesUploadStore.assignMediaFiles(filterMediaTypename(eventData.event.media) || []);
  }, [eventData.event.media]);

  return (
    <div className={"event-view"}>
      <h2 className={"event-view--name"}>{eventData.event.name}</h2>
      {showEditEvent && (
        <Edit className={"event-view--edit"} width={24} height={24} onClick={editVenue} />
      )}
      <div className={"event-view--top-block"}>
        <EventInfo event={eventData.event} />
        <EventHashtags event={eventData.event} hashtagsList={eventData.hashtagIds} />
      </div>
      <div className={"event-view--bottom-block"}>
        <h2 className={"event-view--bottom-block--title"}>Img / Video</h2>
        <ImageVideoUploadCarousel readOnly={true} keyType={"venues"} id={eventData.event.id!} />
      </div>
    </div>
  );
};

export default EventView;
