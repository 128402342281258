import { useCallback, useEffect, useState } from "react";
import { matchRoutes, useLocation, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import BaseError from "../../components/reusable/baseError/BaseError";
import EventForm from "../../forms/EventForm/EventForm";
import { GET_EVENT } from "../../graphql/query/getEvent";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import EventView from "../../pageView/EventView/EventView";
import { observer } from "mobx-react-lite";
import eventsStore from "../../store/eventsStore";

const Event = observer(() => {
  const { venueId, eventId } = useParams();

  const [loading, setLoading] = useState(true);

  const [getEvent, { data, error }] = useLazyQuery(GET_EVENT, {
    fetchPolicy: "no-cache",
  });

  const fetchEvent = useCallback(async () => {
    try {
      if (eventId) {
        await getEvent({
          variables: {
            id: +eventId,
          },
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [eventId, getEvent]);

  useEffect(() => {
    return () => {
      eventsStore.restoreData();
    };
  }, []);

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  useEffect(() => {
    if (data?.findEvent) {
      eventsStore.selectCurrentEvent(data?.findEvent);
    }
  }, [data]);

  const currentLocation = useLocation();
  const eventViewRoutes = [
    { path: "/venues/:venueId/events/view/:eventId" },
    { path: "/events/view/:eventId" },
  ];

  const matches = matchRoutes(eventViewRoutes, currentLocation);

  if (error) {
    return <BaseError>{error?.message}</BaseError>;
  }

  if (loading) {
    return <BaseLoader />;
  }

  return (
    <>
      {matches && matches.length > 0 ? (
        <EventView eventData={data?.findEvent} venueId={venueId} />
      ) : data?.findEvent ? (
        <EventForm eventData={eventsStore.eventData} venueId={venueId} />
      ) : (
        <EventForm venueId={venueId} />
      )}
    </>
  );
});

export default Event;
